import { observable, computed } from 'mobx';
import { getThings } from '../utils';

class LoteTanques {
  @observable arr: LoteTanque[] | null = null;
  @computed
  get length(): number {
    return this.arr ? this.arr.length : 0;
  }

  async populate(bancadas?: boolean): Promise<void> {
    this.arr = await getThings<LoteTanque>(`/lote-tanque/?bancadas=${bancadas}`);
  }

  async getEverything(params?: object): Promise<LoteTanque[]> {
    return await getThings<LoteTanque>('/lote-tanque/', params);
  }

  get(id: number): LoteTanque {
    return this.arr!.filter((item) => item.id === id)[0];
  }

  getByTanque(tanqueID: number): LoteTanque[] {
    return this.arr!.filter((item) => item.tanque.id === tanqueID);
  }

  getByUUID(UUID: string): LoteTanque | undefined {
    return this.arr!.find((item) => item.uuid === UUID);
  }

  getByLote(loteID: number): LoteTanque[] {
    return this.arr!.filter((item) => item.lote.id === loteID);
  }

  getByLoteTanque(loteID: number, tanqueID: number): LoteTanque {
    return this.arr!.filter((item) => item.lote.id === loteID && item.tanque.id === tanqueID)[0];
  }

  filter(callback: (item: LoteTanque, index: number, array: LoteTanque[]) => void): LoteTanque[] {
    return this.arr!.filter(callback);
  }

  map(callback: (item: LoteTanque, index: number, array: LoteTanque[]) => any): any {
    return this.arr!.map(callback);
  }
}

export default LoteTanques;
