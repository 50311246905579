import { TextField } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { observer, useLocalStore } from 'mobx-react-lite';
import React, { useEffect, useMemo } from 'react';
import InputNumero from '../../../components/InputNumero';
import { Modal, ModalBody, ModalHeader } from '../../../components/Modais/styles';
import Select from '../../../components/Select';
import useStore from '../../../services/hooks/useStore';
import { returnTanque } from '../../../services/utils/propriedade';
import Botao from '../../../components/Botoes/Botao';
import { Checkbox, FormControlLabel } from '@mui/material';
import Formatter from '../../../services/Formatter';

interface Props extends ModalProps {
  transferencia: Transferencia | null;
  recarregarTabela: () => Promise<void>;
}
const EditarTransferencia: React.FC<Props> = ({ open, handleClose, transferencia, recarregarTabela }) => {
  const store = useStore();
  const loteOrigem = transferencia?.origem;
  const tanqueOrigem = store.tanques.get(transferencia?.origem.tanque || 0)!;
  const tanqueDestino = store.tanques.get(transferencia?.destino.tanque || 0)!;

  const state = useLocalStore(() => ({
    especie: loteOrigem?.especie_explorada || null,
    loteOrigem: { label: transferencia?.origem.codigo_lote || '', value: transferencia?.origem.lote.toString() || '0' },
    loteDestino: {
      label: transferencia?.destino.codigo_lote || '',
      value: transferencia?.destino.lote.toString() || '0',
    },
    tqOrigem: { label: returnTanque(tanqueOrigem || 0), value: '0' },
    tqDestino: { label: returnTanque(tanqueDestino || 0), value: '0' },
    data: new Date(
      transferencia?.data.substr(3, 2) +
        '/' +
        transferencia?.data.substr(0, 2) +
        '/' +
        transferencia?.data.substr(6, 4),
    ) as MaterialUiPickersDate,
    biomassa: Number(transferencia?.biomassa),
    peso: Number(transferencia?.peso),
    quantidade: Number(transferencia?.quantidade),
    tamanho: Number(transferencia?.tamanho),
    observacao: transferencia?.observacao,
    pesoEmGramas: false,
  }));

  const pesoMedioEmGramas: boolean = useMemo(() => {
    return state.peso > 0 && state.peso < 0.001;
  }, [state.peso]);
  async function editar(): Promise<void> {
    const quantidadeTanque = tanqueOrigem.estoque;

    if (!state.quantidade) {
      store.notificar('Informe a quantidade');
    } else if (state.quantidade > quantidadeTanque) {
      store.notificar('Quantidade de animais maior que o estoque!');
    } else if (String(state.biomassa).length > 12) {
      store.notificar('Biomassa: Certifique-se de que não haja mais de 12 dígitos antes do ponto decimal.');
    } else if (String(state.peso).length > 12) {
      store.notificar('Peso: Certifique-se de que não haja mais de 12 dígitos antes do ponto decimal.');
    } else {
      store.toggleLoader();

      const dados = {
        data: Formatter.dateToString(state.data),
        biomassa: state.biomassa,
        peso: Number((state.peso / 1000).toFixed(3)),
        pls_grama: state.especie === 'Camarão' ? state.peso : 0,
        quantidade: state.quantidade,
        tamanho: state.tamanho,
        observacao: state.observacao,
      };

      try {
        await store.transferencias.editar(transferencia!.id, dados);
      } catch (error) {
        store.notificar('Ocorreu um erro ao editar a transferência!');
      } finally {
        await recarregarTabela();
        handleClose();
        store.toggleLoader();
      }
    }
  }

  function handleChangeQuantidade(value: number) {
    state.quantidade = value;
    const pesoEmKg = state.pesoEmGramas ? state.peso / 1000 : state.peso;

    if (pesoEmKg > 0 && state.quantidade > 0) {
      state.biomassa = Number((pesoEmKg * state.quantidade).toFixed(3));
    }
  }

  const handleChangePesoMedio = (value: number) => {
    state.peso = value;

    const pesoEmKg = state.pesoEmGramas ? value / 1000 : value;

    if (state.biomassa > 0 && pesoEmKg > 0) {
      state.quantidade = Number((state.biomassa / pesoEmKg).toFixed(0));
    }
  };

  const handlePesoEmGramas = (e: any) => {
    const wasChecked = state.pesoEmGramas;
    state.pesoEmGramas = e.target.checked;

    if (wasChecked && !state.pesoEmGramas) {
      state.peso = Number(state.peso / 1000);
    } else if (!wasChecked && state.pesoEmGramas) {
      state.peso = Number(state.peso * 1000);
    }
    handleChangePesoMedio(state.peso);
  };

  const handleBiomassa = (value: number) => {
    state.biomassa = value;
    const pesoEmKg = state.pesoEmGramas ? state.peso / 1000 : state.peso;

    if (state.biomassa > 0 && pesoEmKg > 0) {
      state.quantidade = Number((state.biomassa / pesoEmKg).toFixed(0));
    }
  };
  useEffect(() => {
    state.pesoEmGramas = !state.pesoEmGramas;
  }, []);

  return (
    <Modal onClose={handleClose} open={open}>
      <ModalHeader>Editar Transferência</ModalHeader>
      <ModalBody>
        <div style={{ width: '100%' }}>
          {store.lotes.arr && <Select disabled value={state.loteOrigem} placeholder="Lote Origem" options={[]} />}
        </div>
        <div style={{ width: '100%', marginTop: 10 }}>
          <Select disabled value={state.tqOrigem} placeholder="Tanque Origem" options={[]} />
        </div>
        <div style={{ width: '100%' }}>
          {store.lotes.arr && <Select disabled value={state.loteDestino} placeholder="Lote Destino" options={[]} />}
        </div>
        <div style={{ width: '100%', marginTop: 10 }}>
          <Select disabled value={state.tqDestino} placeholder="Tanque Destino" options={[]} />
        </div>
        <KeyboardDatePicker
          autoOk
          disableToolbar
          variant="inline"
          format="dd/MM/yyyy"
          margin="normal"
          label="Data"
          value={state.data}
          onChange={(date) => (state.data = date)}
        />

        <InputNumero value={state?.biomassa || 0} onChange={handleBiomassa} precision="3" label="Biomassa Total (kg)" />
        <div style={{ display: 'flex', justifyContent: 'flex-start', width: '100%' }}>
          <FormControlLabel
            labelPlacement="end"
            control={<Checkbox checked={state.pesoEmGramas} onChange={handlePesoEmGramas} color="primary" />}
            label={`Peso Médio (g)`}
          />
        </div>
        <InputNumero
          value={pesoMedioEmGramas ? state.peso * 1000 : state.peso}
          onChange={handleChangePesoMedio}
          precision="3"
          label={`Peso Médio (${state.pesoEmGramas ? 'g' : 'kg'})`}
        />

        <InputNumero
          value={state?.quantidade || 0}
          onChange={handleChangeQuantidade}
          precision="0"
          label="Quantidade Total"
        />
        <InputNumero
          value={state?.tamanho || 0}
          onChange={(value) => (state.tamanho = value)}
          precision="2"
          label="Tamanho Médio (cm) *Opcional"
        />
        <TextField
          label="Observação *Opcional"
          value={state.observacao}
          onChange={(e) => (state.observacao = e.target.value)}
        />
        <div style={{ width: '100%', display: 'flex' }}>
          <Botao onClick={handleClose} variant="contained" cor="#FC7467">
            Cancelar
          </Botao>
          <Botao onClick={editar} variant="contained" cor="#00C853">
            Editar
          </Botao>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default observer(EditarTransferencia);
