import { stateContext } from '../hooks/useStore';

const store = stateContext.state;

class Sync {
  /**
   * Perfil
   */

  async perfil(): Promise<void> {
    store.toggleLoader();
    await Promise.all([store.cidades.populate(), store.estados.populate(), store.atividades.populate()]);
    store.toggleLoader();
  }

  /**
   * Tanques
   */

  async tanques(): Promise<void> {
    store.toggleLoader();
    await Promise.all([
      store.tiposDeTanque.populate(),
      store.origensDaAgua.populate(),
      store.situacoesDeTanque.populate(),
    ]);
    store.toggleLoader();
  }

  async historicoTanque(): Promise<void> {
    store.toggleLoader();
    await Promise.all([
      store.tanques.populate(),
      store.lotes.populate(),
      store.tiposDeTanque.populate(),
      store.origensDaAgua.populate(),
      store.situacoesDeTanque.populate(),
    ]);
    store.toggleLoader();
  }

  async reservatorios(): Promise<void> {
    store.toggleLoader();
    await Promise.all([store.origensDaAgua.populate(), store.situacoesDeTanque.populate()]);
    store.toggleLoader();
  }

  async bancadas(): Promise<void> {
    store.toggleLoader();
    await Promise.all([
      store.reservatorios.populate(),
      store.origensDaAgua.populate(),
      store.situacoesDeTanque.populate(),
    ]);
    store.toggleLoader();
  }

  /**
   * Produção
   */

  async povoamento(): Promise<void> {
    store.toggleLoader();
    await Promise.all([
      store.fasesLote.populate(),
      store.especies.populate(),
      store.loteTanque.populate(),
      store.lotes.populate(),
      store.fornecedores.populate(),
      store.categorias.populate(),
      store.contas.populate(),
      store.tiposDePagamento.populate(),
      store.cidades.populate(),
      store.estados.populate(),
    ]);
    store.toggleLoader();
  }

  async plantio(): Promise<void> {
    store.toggleLoader();
    await Promise.all([
      store.fasesLote.populate(),
      store.especiesAquaponia.populate(),
      store.loteTanque.populate(),
      store.lotes.populate(),
      store.fornecedores.populate(),
    ]);
    store.toggleLoader();
  }

  async biometrias(): Promise<void> {
    store.toggleLoader();
    await Promise.all([
      store.tanques.populate(),
      store.lotes.populate(),
      store.loteTanque.populate(),
      store.fasesLote.populate(),
    ]);
    store.toggleLoader();
  }

  async mortalidades(): Promise<void> {
    store.toggleLoader();
    await Promise.all([ store.tiposDeMorte.populate(), store.loteTanque.populate()]);
    store.toggleLoader();
  }

  async transferencias(): Promise<void> {
    store.toggleLoader();
    await Promise.all([
      store.lotes.populate(),
      // store.transferencias.populate(),
      store.loteTanque.populate(),
      store.tanques.populate(),
      store.especies.populate(),
      store.fasesLote.populate(),
      store.fornecedores.populate(),
    ]);
    store.toggleLoader();
  }

  async compraDeRacoes(ativo?: number): Promise<void> {
    store.toggleLoader();
    await Promise.all([
      store.racoes.populate(ativo),
      store.cidades.populate(),
      store.estados.populate(),
      store.tiposDeFornecedor.populate(),
      store.fornecedores.populate(),
      store.contas.populate(),
      store.categorias.populate(),
      store.tiposDePagamento.populate(),
      store.marcas.populate(),
    ]);
    store.toggleLoader();
  }

  async compraDeInsumos(mostrarTodos?: boolean): Promise<void> {
    store.toggleLoader();
    await Promise.all([
      store.comprasDeInsumos.populate(),
      store.insumos.populate(mostrarTodos),
      store.tiposDeFornecedor.populate(),
      store.fornecedores.populate(),
      store.contas.populate(),
      store.tiposDePagamento.populate(),
      store.categorias.populate(),
      store.cidades.populate(),
      store.estados.populate(),
    ]);
    store.toggleLoader();
  }

  // async arracoamento(): Promise<void> {
  //   store.toggleLoader();
  //   await Promise.all([
  //     store.racoes.populate(),
  //     store.lotes.populate(),
  //     store.biometrias.populate(),
  //     store.tanques.populate(),
  //     store.loteTanque.populate(),
  //   ]);
  //   store.toggleLoader();
  // }

  /**
   * Vendas
   */

  async vendas(): Promise<void> {
    store.toggleLoader();
    await Promise.all([
      store.lotes.populate(),
      store.biometrias.populate(),
      store.tanques.populate(),
      store.clientes.populate(),

      store.tiposDeCliente.populate(),
      store.cidades.populate(),
      store.estados.populate(),

      store.loteTanque.populate(),
      store.vendas.populate(),

      store.contas.populate(),
      store.categorias.populate(),
      store.movimentacoes.populate(),
      store.tiposDePagamento.populate(),

      store.fasesLote.populate(),
    ]);
    store.toggleLoader();
  }
  async vendas2(): Promise<void> {
    store.toggleLoader();
    await Promise.all([
      store.vendas.getMeses(store.propriedade!.id),
      store.lotes.populate(),
      store.clientes.populate(),
      store.categorias.populate(),
      store.contas.populate(),
      store.tiposDePagamento.populate(),
      store.fasesLote.populate(),
      store.loteTanque.populate(),
      store.tanques.populate(),
    ]);
    store.toggleLoader();
  }

  async vendasModal(): Promise<void> {
    store.toggleLoader();
    await Promise.all([store.fasesLote.populate(), store.loteTanque.populate(), store.tanques.populate()]);
    store.toggleLoader();
  }

  async clientes(): Promise<void> {
    store.toggleLoader();
    await Promise.all([
      store.vendas.populate(),
      store.clientes.populate(),
      store.tiposDeCliente.populate(),
      store.cidades.populate(),
      store.estados.populate(),
    ]);
    store.toggleLoader();
  }

  /**
   * Financeiro
   */

  async movimentacoes(): Promise<void> {
    store.toggleLoader();
    await Promise.all([
      store.movimentacoes.getReceitas(store.propriedade?.id as number),
      store.movimentacoes.getDespesas(store.propriedade?.id as number),
      store.movimentacoes.getContas(store.propriedade?.id as number),
      store.fornecedores.populate(),
      store.clientes.populate(),
      store.tiposDePagamento.populate(),
      store.tiposDeFornecedor.populate(),
      store.estados.populate(),
      store.cidades.populate(),
      store.categorias.populate(),
      store.contas.populate(),
    ]);
    store.toggleLoader();
  }
  async movimentacoesCriacao(): Promise<void> {
    store.toggleLoader();
    await Promise.all([
      store.movimentacoes.getReceitas(store.propriedade?.id as number),
      store.movimentacoes.getDespesas(store.propriedade?.id as number),
      store.movimentacoes.getContas(store.propriedade?.id as number),
    ]);
    store.toggleLoader();
  }
  async movimentacoesModal(): Promise<void> {
    store.toggleLoader();
    await Promise.all([store.clientes.populate(), store.tanques.populate()]);
    store.toggleLoader();
  }

  async contas(): Promise<void> {
    store.toggleLoader();
    await Promise.all([store.contas.populate()]);
    store.toggleLoader();
  }

  async cheques(): Promise<void> {
    store.toggleLoader();
    await Promise.all([store.cheques.populate(), store.contas.populate()]);
    store.toggleLoader();
  }

  async categorias(): Promise<void> {
    store.toggleLoader();
    await Promise.all([store.categorias.populate()]);
    store.toggleLoader();
  }

  async fornecedores(): Promise<void> {
    store.toggleLoader();
    await Promise.all([
      store.fornecedores.populate(),
      store.tiposDeFornecedor.populate(),
      store.cidades.populate(),
      store.estados.populate(),
    ]);
    store.toggleLoader();
  }

  async temperaturas(): Promise<void> {
    store.toggleLoader();
    await Promise.all([store.temperatura.populate()]);
    store.toggleLoader();
  }

  /**
   * Relatórios
   */
  async painelManejo(): Promise<void> {
    store.toggleLoader();
    await Promise.all([
      store.tanques.populate(),
      store.racoes.populate(),
      store.mortalidades.populate(),
      store.tiposDeMorte.populate(),
      store.lotes.populate(),
      store.loteTanque.populate(),
      store.biometrias.populate(),
    ]);
    store.toggleLoader();
  }

  async painelZootecnico(): Promise<void> {
    store.toggleLoader();
    await Promise.all([store.tanques.populate(), store.racoes.populate()]);
    store.toggleLoader();
  }

  async desempenhoLotes(): Promise<void> {
    store.toggleLoader();
    await Promise.all([store.fasesLote.populate()]);
    store.toggleLoader();
  }

  async fluxoDeCaixa(): Promise<void> {
    store.toggleLoader();
    await Promise.all([store.movimentacoes.populate(), store.contas.populate(), store.categorias.populate()]);
    store.toggleLoader();
  }

  /**
   *Qualidade da Água
   */
  async qualidadeDaAgua(): Promise<void> {
    store.toggleLoader();
    await Promise.all([
      store.tanques.populate(),
      store.loteTanque.populate(),
      store.lotes.populate(),
      store.parametroValor.populate(),
      store.qualidadeDaAgua.getPaginated({
        page: 0,
        page_size: 25,
        propriedade: store.propriedade!.id,
      }),
    ]);
    store.toggleLoader();
  }
  /**
   *Agua de reservatorios
   */
  async aguaDeReservatorios(): Promise<void> {
    store.toggleLoader();
    await Promise.all([
      store.tanques.populate(),
      store.loteTanque.populate(),
      store.lotes.populate(),
      store.parametroValor.populate(),
      store.aguaDeReservatorios.getPaginated(),
      store.reservatorios.populate()
    ]);
    store.toggleLoader();
  }

  /**
   * Usuários
   */
  async usuarios(): Promise<void> {
    store.toggleLoader();
    await Promise.all([store.usuarios.populate(), store.tiposDeUsuario.populate()]);
    store.toggleLoader();
  }
}

const syncModel = new Sync();

export default syncModel;
