import { observable, computed } from 'mobx';
import { getThings, api, newGetThings, getThingsV21, apiV2 } from '../utils';
import { stateContext } from '../hooks/useStore';
import Formatter from '../Formatter';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

type TanqueData = {
  propriedade: number;
  tanque_tipo: number;
  agua_origem: number;
  codigo: string;
  tamanho: number;
  nome: string | null;
  capacidade: number;
  situacao_tanque: number;
};

// type TanquesPopulateProps = {
//   isAquaponia?: boolean;
// };

class Tanques {
  @observable arr: Tanque[] | null = null;
  @computed
  get length(): number {
    return this.arr ? this.arr.length : 0;
  }

  // async populate({ isAquaponia = false }: TanquesPopulateProps): Promise<void> {
  //   const store = stateContext.state;
  //   await apiV2
  //     .get('/tanque/modals/', {
  //       params: {
  //         prop: store.propriedade?.id,
  //         bancadas: isAquaponia,
  //       },
  //     })
  //     .then((res) => {
  //       this.arr = res.data.results;
  //     })
  //     .catch(() => {});
  // }
  async populate(bancadas?: boolean): Promise<void> {
    const store = stateContext.state;
    await apiV2
      .get('/tanque/modals/', {
        params: {
          prop: store.propriedade?.id,
          bancadas
        },
      })
      .then((res) => {
        this.arr = res.data.results;
      })
      .catch(() => {});
  }
  async populateAquaponia(): Promise<void> {
    const store = stateContext.state;
    await apiV2
      .get('/tanque/modals/', {
        params: {
          prop: store.propriedade?.id,
          bancadas: true,
        },
      })
      .then((res) => {
        this.arr = res.data.results;
      })
      .catch(() => {});
  }

  async getEverything(params?: object): Promise<Tanque[]> {
    return await newGetThings<Tanque>('/web/tanques/', params);
  }

  async getHistorico(tanqueID: number): Promise<Historico[]> {
    return await getThings<Historico>('/historico/', {
      tanque: tanqueID,
    });
  }
  async getHistorico2(tanqueID: number, propriedadeID: number): Promise<Historico2> {
    return await getThingsV21<Historico2>('/tanque/historico/', {
      tanque: tanqueID,
      propriedade: propriedadeID,
    });
  }

  /**
   * Capacidade Total dos tanques em toneladas
   */
  @computed
  get capacidadeTotal(): number {
    if (this.arr) {
      const capacidade = this.arr!.reduce((prevTanque, tanque): number => {
        return prevTanque + tanque.capacidade;
      }, 0);
      return capacidade / 1000;
    } else return 0;
  }

  /**
   * Produção Total dos tanques em toneladas
   */
  @computed
  get producaoTotal(): number {
    let producao = 0;

    this.arr?.map((tanque) => {
      if (tanque.estoque !== 0) {
        producao += tanque.biomassa / 1000;
      }
    });

    return producao / 1000;
  }

  get(id: number): Tanque {
    return this.arr!.filter((item) => item.id === id)[0];
  }

  getByUUID(UUID: string): Tanque {
    return this.arr!.filter((item) => item.uuid === UUID)[0];
  }

  getByLote(loteID: number): Tanque[] {
    const store = stateContext.state;

    const loteTanques = store.loteTanque.getByLote(loteID);

    return loteTanques.map((loteTanque) => {
      return loteTanque.tanque;
    });
  }

  getEstoqueByID(id: number): number {
    return this.arr!.find((item) => item.id === id)?.estoque || 0;
  }

  getPesoMedio(id: number): number {
    const tanque = this.get(id);
    let pm = 0;

    tanque.lotes.forEach((lote) => (pm += lote.peso_medio));

    pm = pm / tanque.lotes.length;
    return pm;
  }

  async criar(dados: TanqueData): Promise<void> {
    const store = stateContext.state;
    const codigo = this.length + 1;

    dados.codigo = 'TQ - ' + codigo;

    await api
      .post('/tanques/', dados)
      .then(async () => {
        store.notificar('Tanque cadastrado com sucesso');
        await store.tanques.populate();
      })
      .catch(() => store.notificar('Ocorreu um erro ao cadastrar o tanque'));
  }

  async criarVarios(dados: TanqueData, quantidade: number): Promise<void> {
    const store = stateContext.state;

    let codigo = 1;
    if (store.tanques.arr && store.tanques.length > 0) {
      codigo = store.tanques.length + 1;
    }

    dados.nome = null;

    for (let i = 0; i < quantidade; i++) {
      const tanque = { ...dados };
      tanque.codigo = 'TQ - ' + (codigo + i);
      await api.post(`/tanques/?propriedade=${store.propriedade!.id}`, tanque);
    }

    store.notificar('Tanques cadastrados com sucesso');
    await store.tanques.populate();
  }

  async editar(dados: TanqueData, tanqueID: number): Promise<void> {
    const store = stateContext.state;

    await api
      .put(`/tanques/${tanqueID}/?propriedade=${store.propriedade!.id}`, dados)
      .then(async () => {
        store.notificar('Tanque editado com sucesso!');
        await Promise.all([store.tanques.populate(), store.lotes.populate(), store.loteTanque.populate()]);
      })
      .catch(() => store.notificar('Ocorreu um erro ao editar, tente novamente'));
  }

  async excluir(tanqueID: number): Promise<void> {
    const store = stateContext.state;
    const historico = await this.getHistorico(tanqueID);

    if (historico) {
      if (historico?.length > 0) {
        store.notificar('O tanque possui histórico e não pode ser deletado!');
      } else {
        store.toggleLoader();
        await api.delete(`/tanques/${tanqueID}/?propriedade=${store.propriedade!.id}`);
        await Promise.all([store.tanques.populate(), store.lotes.populate(), store.loteTanque.populate()]);
        store.notificar('Tanque excluído com sucesso!');
        store.toggleLoader();
      }
    }
  }

  filter(callback: (item: Tanque, index: number, array: Tanque[]) => void): Tanque[] {
    return this.arr!.filter(callback);
  }

  map(callback: (item: Tanque, index: number, array: Tanque[]) => any): any {
    return this.arr!.map(callback);
  }
  async lancarMortalidadeVenda(item: any, data: MaterialUiPickersDate): Promise<void> {
    const store = stateContext.state;
    const promiseArr: Array<Promise<void>> = [];
    item.map((tanque: any) => {
      if (tanque.item.estoque > 0) {
        const addMortalidade = async (): Promise<void> => {
          const res = store.loteTanque.getByTanque(tanque.item.id);
          await api.post(`/mortalidade/?propriedade=${store.propriedade!.id}`, {
            lote_tanque: res[0].id as any,
            data: Formatter.dateToString(data),
            quantidade: tanque.item.estoque - tanque.quantidade,
            tipo_morte: 7,
            observacao: null,
          });
        };

        promiseArr.push(addMortalidade());
      } else return null;

      return null;
    });

    await Promise.all(promiseArr);

    store.notificar('Mortalidade lançada com sucesso!');
  }
}

export default Tanques;
