import React, { useEffect, useState } from 'react';
import { TextField, IconButton, FormControlLabel, Checkbox } from '@material-ui/core';
import { Modal, ModalHeader, ModalBody, Divisor } from '../../../components/Modais/styles';
import Botao from '../../../components/Botoes/Botao';
import useStore from '../../../services/hooks/useStore';
import SelecionarBancada from './SelecionarBancada';
import { TanqueItem } from '../Povoamento/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import { useLocalStore, observer } from 'mobx-react-lite';
import InputNumero from '../../../components/InputNumero';
import SelecionarFornecedor from '../Povoamento/SelecionarFornecedor';
import { api } from '../../../services/utils';
import useModal from '../../../services/hooks/useModal';
import Select from '../../../components/Select';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import add from 'date-fns/add';
import Formatter from '../../../services/Formatter';
import RegistrarDespesa from '../../../components/Modais/RegistrarDespesa';

type State = {
  codigo: string;
  especie: SelectRow | null;
  fornecedor: SelectRow | null;
  fornecedorNome: string;
  fase: SelectRow | null;
  data: MaterialUiPickersDate;
  valor: number;
  pesoMedio: number;
  pesoMedioEsperado: number;
  tamanho: number;
  tanques: SelectedTanque[];
  quantidade: number;
  diasPrevistos: number;
  dataPrevista: MaterialUiPickersDate;
  selectFornecedor: boolean;
};
const CadastrarPlantio: React.FC<ModalProps> = ({ open, handleClose }) => {
  const store = useStore();
  const state = useLocalStore(
    (): State => ({
      codigo: '',
      especie: null,
      fornecedor: null,
      fornecedorNome: '',
      fase: null,
      valor: 0,
      data: new Date(),
      pesoMedio: 0,
      pesoMedioEsperado: 0,
      tamanho: 0,
      tanques: [],
      quantidade: 0,
      diasPrevistos: 0,
      dataPrevista: new Date(),

      selectFornecedor: false,
    }),
  );
  const modalTanques = useModal();
  const modalFornecedor = useModal();
  const modalDespesa = useModal();

  const [financeChecked, setFinanceChecked] = useState(true);

  const handleFinanceChecked = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setFinanceChecked(event.target.checked);
  };

  async function checarCadastro(e: React.FormEvent<HTMLFormElement>): Promise<void> {
    e.preventDefault();
    if (!state.especie) store.notificar('Selecione a espécie do lote');
    else if (!state.fornecedor) store.notificar('Selecione um fornecedor');
    else if (!state.fase) store.notificar('Selecione a fase do lote');
    else if (state.valor === 0) store.notificar('Informe o valor do lote');
    else if (state.pesoMedio === 0) store.notificar('Informe o peso médio do lote');
    else if (state.pesoMedioEsperado === 0) store.notificar('Informe o peso médio esperado do lote');
    else if (state.tanques.length === 0) store.notificar('Selecione um ou mais tanques');
    else {
      if (!financeChecked) {
        const movimentacaoMock = {
          propriedade: 0,
          data: new Date(),
          descricao: '',
          conta: 0,
          categoria: 0,
          situacao: false,
          foto: null,
          quantidade: 0,
          desconto: 0,
          valor_unitário: 0,
          valor_total: 0,
          fornecedor: 0,
          observacao: '',
          lote_tanque_movimentacao: [],
          parcelas: [],
        };
        cadastrar(movimentacaoMock);
      } else {
        modalDespesa.open();
      }
    }
  }
  function cancelarSelecao(): void {
    state.tanques = [];
    modalTanques.close();
  }
  function addTanque(tanqueID: number, quantidade: string, plsGrama: number, biomassa: number): void {
    if (state.tanques.filter((tanque) => tanque.item.id === tanqueID).length > 0) {
      state.tanques.filter((tanque) => tanque.item.id === tanqueID)[0].quantidade = quantidade;
    } else {
      const tanque = { item: store.tanques.get(tanqueID)!, quantidade, plsGrama, biomassa };
      state.tanques.push(tanque);
      state.quantidade += parseInt(quantidade);
    }

    if (state.especie?.label === 'Camarão') {
      let total = 0;

      state.tanques.map((tanque) => {
        return (total += ((tanque.biomassa || 0) * 1000) / parseInt(tanque.quantidade || '0'));
      });

      state.pesoMedio = parseFloat((total / state.tanques.length).toFixed(3));
    }
  }
  function removerTanque(tanque: any): void {
    state.quantidade -= parseInt(tanque.quantidade);
    const newTanques = state.tanques.filter((tanqueArr) => tanqueArr !== tanque);
    state.tanques = newTanques;
  }
  async function cadastrar(movimentacao: any): Promise<void> {
    store.toggleLoader();
    modalTanques.close();
    modalDespesa.close();

    const loteTanque: any = [];

    state.tanques.map((tanque: any) => {
      return loteTanque.push({
        tanque: tanque.item.id,
        quantidade: parseInt(tanque.quantidade),
        fase_lote: parseInt(state.fase!.value),
      });
    });

    const dataPlantio = {
      especie_explorada: parseInt(state.especie!.value),
      fornecedor: parseInt(state.fornecedor!.value),
      codigo: state.codigo,
      nome: null,
      peso_medio: state.pesoMedio,
      pm_esperado: state.pesoMedioEsperado,
      quantidade: state.quantidade,
      valor_total: state.valor,
      data: Formatter.dateToString(state.data),
      lote_tanque_lote: loteTanque,
      fase_lote: parseInt(state.fase!.value),
      tamanho_medio: state.tamanho ? state.tamanho : undefined,
      dias_previstos: state.diasPrevistos,
      data_prevista: Formatter.dateToString(state.dataPrevista),
      propriedade: store.propriedade?.id,
    };

    if (financeChecked) {
      await api
        .post(`/lote/?propriedade=${store.propriedade!.id}`, dataPlantio)
        .then(async (res) => {
          const lote_tanque_movimentacao: any = [];

          res.data.lote_tanque_lote.map((item: any) => {
            return lote_tanque_movimentacao.push({
              lote_tanque: item.id,
            });
          });

          const movimentacaoData = {
            propriedade: store.propriedade!.id,
            data: movimentacao.data,
            descricao: 'Povoamento ' + state.codigo,
            conta: movimentacao.conta,
            fornecedor: parseInt(state.fornecedor!.value),
            categoria: 3,
            situacao: movimentacao.pago,
            quantidade: state.quantidade,
            lote_tanque_movimentacao: lote_tanque_movimentacao,
            valor_unitario: parseFloat((res.data.valor_total / state.quantidade).toFixed(2)),
            valor_total: res.data.valor_total,
            parcelas: movimentacao.parcelas,
            documento: movimentacao.documento,
          };
          const respostinha = await api.post(`/movimentacao/?propriedade=${store.propriedade!.id}`, movimentacaoData);
          const dataLote = {
            movimentacao: respostinha.data.id,
          };
          await api.patch(`/lote/${res.data.uuid}/?propriedade=${store.propriedade!.id}`, dataLote);
          await Promise.all([
            store.lotes.populate(),
            store.movimentacoes.populate(),
            store.loteTanque.populate(),
            store.contas.populate(),
            handleClose(),
          ]).then(() => store.notificar('Povoamento cadastrado com sucesso!'));
        })
        .catch(() => {
          store.notificar('Ocorreu um erro no plantio!(Movimentacao)');
        });
    } else {
      await api.post(`/lote/?propriedade=${store.propriedade!.id}`, dataPlantio).catch(() => {
        store.notificar('Ocorreu um erro no plantio!');
      });

      await Promise.all([
        store.lotes.populate(),
        store.movimentacoes.populate(),
        store.loteTanque.populate(),
        store.contas.populate(),
        handleClose(),
      ]).then(() => store.notificar('Plantio cadastrado com sucesso!'));
    }

    store.toggleLoader();
  }

  useEffect(() => {
    (async () => {
      store.toggleLoader();
      await store.tanques.populateAquaponia();
      store.toggleLoader();
    })();
    // eslint-disable-next-line
  }, []);

  const TanquesSelecionados: React.FC = () => (
    <>
      {state.tanques.length > 0 &&
        state.tanques.map((tanque: any) => (
          <TanqueItem key={tanque.item.id}>
            <p>{tanque.item.nome || tanque.item.codigo}</p>
            <p>{Formatter.formatNumber(0, tanque.quantidade)}</p>
            <IconButton onClick={() => removerTanque(tanque)}>
              <DeleteIcon />
            </IconButton>
          </TanqueItem>
        ))}
    </>
  );


  return (
    <Modal onClose={handleClose} open={open}>
      <ModalHeader>Novo Plantio</ModalHeader>
      <ModalBody>
        <form onSubmit={checarCadastro}>
          <TextField
            required
            label="Lote do Plantio"
            value={state.codigo}
            onChange={(e) => (state.codigo = e.target.value)}
          />
          <div style={{ width: '100%' }}>
            {store.especiesAquaponia.arr && (
              <Select
                value={state.especie}
                placeholder="Espécie"
                onChange={(e: any) => (state.especie = e)}
                options={store.especiesAquaponia.getSelectRows()}
              />
            )}
          </div>
          <div style={{ width: '100%' }}>
            {store.fornecedores.arr && (
              <Select
                value={state.fornecedor}
                placeholder="Fornecedor"
                onChange={(e: any) => (state.fornecedor = e)}
                options={store.fornecedores?.getSelectRows()}
                onFocus={(e) => {
                  e.target.blur();
                  state.selectFornecedor = false;
                  modalFornecedor.open();
                }}
              />
            )}
          </div>
          <KeyboardDatePicker
            autoOk
            disableToolbar
            variant="inline"
            format="dd/MM/yyyy"
            margin="normal"
            label="Data"
            value={state.data}
            onChange={(date) => {
              state.data = date;

              state.dataPrevista = add(state.data!, { days: state.diasPrevistos });
            }}
          />
          <Botao onClick={modalTanques.open}>Selecione a(s) bancada(s)</Botao>
          <TanquesSelecionados />
          <div style={{ width: '100%' }}>
            <Select
              value={state.fase}
              placeholder="Fase"
              onChange={(e: any) => (state.fase = e)}
              options={store.fasesLote.getSelectRows()}
            />
          </div>

          <InputNumero disabled value={state.quantidade} precision="0" label="Quantidade" />
          <InputNumero
            value={state.valor}
            onChange={(value) => (state.valor = value)}
            precision="2"
            label="Valor do lote (R$)"
          />
          <InputNumero
            value={state.pesoMedio}
            onChange={(value) => (state.pesoMedio = value)}
            precision="3"
            label="Peso Médio (g)"
          />

          <Divisor>
            <p>Previsão de Colheta</p>
          </Divisor>
          <InputNumero
            value={state.pesoMedioEsperado}
            onChange={(value) => (state.pesoMedioEsperado = value)}
            precision="3"
            label="Peso Médio Esperado (g)"
          />
          <TextField
            value={state.diasPrevistos}
            onChange={(e: any) => {
              state.diasPrevistos = parseInt(e.target.value);
              state.dataPrevista = add(state.data!, { days: parseInt(e.target.value) });
            }}
            type="number"
            label="Dias Previstos"
            inputProps={{ min: 0 }}
          />

          <FormControlLabel
            control={
              <Checkbox defaultChecked color="default" checked={financeChecked} onChange={handleFinanceChecked} />
            }
            label="Cadastrar financeiro"
          />

          <div style={{ width: '100%', display: 'flex' }}>
            <Botao onClick={handleClose} variant="contained" cor="#FC7467">
              Cancelar
            </Botao>
            <Botao type="submit" variant="contained" cor="#00C853">
              Cadastrar
            </Botao>
          </div>
        </form>
      </ModalBody>
      {modalTanques.state ? (
        <SelecionarBancada
          especie={state.especie?.label as string}
          addBancada={addTanque}
          cancelar={cancelarSelecao}
          selectedBancadas={state.tanques}
          handleClose={modalTanques.close}
          open={modalTanques.state}
        />
      ) : null}
      {modalDespesa.state && (
        <RegistrarDespesa
          data={state.data}
          onConfirm={cadastrar}
          open={modalDespesa.state}
          handleClose={modalDespesa.close}
          valorTotal={state.valor}
        />
      )}
      <SelecionarFornecedor
        onConfirm={(fornecedor: SelectRow) => {
          modalFornecedor.close();
          state.fornecedor = fornecedor;
        }}
        open={modalFornecedor.state}
        handleClose={modalFornecedor.close}
      />
    </Modal>
  );
};

export default observer(CadastrarPlantio);
