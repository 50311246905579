import { observable, computed } from 'mobx';
import { api, apiV2, getThings, newGetThings } from '../utils';
import { stateContext } from '../hooks/useStore';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import Formatter from '../Formatter';

class Lotes {
  @observable arr: Lote[] | null = null;
  @computed
  get length(): number {
    return this.arr ? this.arr.length : 0;
  }

  async populate(bancadas?: boolean): Promise<void> {
    const store = stateContext.state;
    await apiV2
      .get('/lote/modals/', {
        params: {
          prop: store.propriedade!.id,
          bancadas,
        },
      })
      .then((res) => {
        this.arr = res.data.results;
      })
      .catch(() => {});
  }
  getSelectRows(): SelectRow[] {
    return this.arr!.map((item: any) => {
      return { value: item.id.toString(), label: item.codigo };
    });
  }
  async getEverything(params?: object): Promise<Lote[]> {
    return await newGetThings<Lote>('/web/lotes/', params);
  }

  async getHistorico(loteID: number): Promise<Historico[]> {
    const historico = await getThings<Historico>('/historico/', {
      lote: loteID,
    });

    const arr: Historico[] = [];

    for (const item of historico.reverse()) {
      arr.push(item);
      if (item.tipo_morte && item.tipo_morte.id === 7) {
        break;
      }
    }

    return arr.reverse();
  }

  get(id: number): Lote {
    return this.arr!.filter((item) => item.id === id)[0];
  }

  getByUUID(UUID: string): Lote {
    return this.arr!.filter((item) => item.uuid === UUID)[0];
  }

  async finalizarLote(loteID: number, data: MaterialUiPickersDate): Promise<void> {
    const store = stateContext.state;
    const lote = this.get(loteID);
    const promiseArr: Array<Promise<void>> = [];

    lote.tanques.map((tanque) => {
      if (tanque.estoque > 0) {
        const addMortalidade = async (): Promise<void> => {
          const res = store.loteTanque.getByTanque(tanque.tanque_id);
          await api.post(`/mortalidade/?propriedade=${store.propriedade!.id}`, {
            lote_tanque: res[0].id as any,
            data: Formatter.dateToString(data),
            quantidade: tanque.estoque,
            tipo_morte: 7,
            observacao: null,
          });
        };

        promiseArr.push(addMortalidade());
      } else return null;

      return null;
    });

    await Promise.all(promiseArr);

    await api.patch(`/lote/${lote.uuid}/?propriedade=${store.propriedade!.id}`, {
      fase_lote: store.fasesLote.get(6)?.id,
    });
    store.notificar('Lote finalizado com sucesso!');
  }
  async finalizarLotePovoamento(loteID: number, data: MaterialUiPickersDate): Promise<void> {
    const store = stateContext.state;
    const lote = this.get(loteID);

    const promiseArr: Array<Promise<void>> = [];

    lote.tanques.map((tanque) => {
      if (tanque.estoque > 0) {
        const addMortalidade = async (): Promise<void> => {
          await api.post(`/mortalidade/?propriedade=${store.propriedade!.id}`, {
            lote_tanque: store.loteTanque
              .getByTanque(tanque.tanque_id)
              ?.filter((loteTanque) => loteTanque.lote.id === loteID)[0].id as any,
            data: Formatter.dateToString(data),
            quantidade: tanque.estoque,
            tipo_morte: 7,
            observacao: null,
          });
        };

        promiseArr.push(addMortalidade());
      } else return null;

      return null;
    });

    await Promise.all(promiseArr);

    await api.patch(`/lote/${lote.uuid}/?propriedade=${store.propriedade!.id}`, {
      fase_lote: store.fasesLote.get(6)?.id,
    });
    store.notificar('Lote finalizado com sucesso!');
  }

  filter(callback: (item: Lote, index: number, array: Lote[]) => void): Lote[] {
    return this.arr!.filter(callback);
  }

  map(callback: (item: Lote, index: number, array: Lote[]) => any): any {
    return this.arr!.map(callback);
  }
}

export default Lotes;
