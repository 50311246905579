import React, { useEffect } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import useStore from '../../../services/hooks/useStore';
import { Body } from '../../../components/Body';
import Display from '../../../components/Tabela/Display';
import Botao from '../../../components/Botoes/Botao';
import EditarLote from './EditarLote';
import { Info } from '../../../components/Historico/styles';
import useModal from '../../../services/hooks/useModal';
import Confirmar from '../../../components/Modais/Confirmar';
import { useLocalStore, observer } from 'mobx-react-lite';
import { deletarLote } from '../../../services/utils/producao';
import Formatter from '../../../services/Formatter';
import Historico from '../../../components/Historico';
import { apiMain, apiV2 } from '../../../services/utils';
import { permissoes } from '../../../services/utils/permissoes';
import { Button } from '@material-ui/core';
import X9Logger from '../../../services/X9Logger';

type State = {
  lote: Lote | null;
  tanques: Lote['tanques'] | null;
  historico: Historico[] | null;
  sync: boolean;
};
const HistoricoLote: React.FC = () => {
  const { id }: { id: string } = useParams();

  const store = useStore();
  const history = useHistory();
  const location: any = useLocation();
  const state = useLocalStore(
    (): State => ({
      lote: null,
      tanques: [],
      historico: null,
      sync: false,
    }),
  );
  const modalExclusao = useModal();
  const modalEditar = useModal();
  const temPermissaoEscrita = store.checkPermissions(permissoes.PRODUCAO_POVOAMENTO_ESCRITA);

  async function deletar(): Promise<void> {
    modalExclusao.close();

    deletarLote(store.lotes.get(parseInt(id))!).then(() => {
      history.push('/producao/povoamento');
    });
  }

  function validaInteracao(): boolean {
    if (state.lote && state.lote?.historicos.filter((registro) => registro.tipo !== 'povoamento').length === 0) {
      return true;
    } else {
      return false;
    }
  }

  async function sync(): Promise<void> {
    store.toggleLoader();

    await Promise.all([store.lotes.populate(), store.vendas.populate()]);

    state.sync = true;
    try {
      await apiV2
        .get(`/lote/list-historico/${id}/`, {
          params: {
            prop: store.propriedade?.id,
          },
        })
        .then((res) => {
          state.lote = res.data;
          state.tanques = res.data.tanques;
        });
    } catch (error) {
      console.log(error);
    }
    store.toggleLoader();
  }

  useEffect(() => {
    store.propriedade && sync();
    // eslint-disable-next-line
  }, [store.propriedade]);

  const ExcluirLote: React.FC = () => {
    const lote = state.lote;

    return (
      <div style={{ width: '100%' }}>
        <b>Lote: </b> {lote && lote.codigo}
        <br />
        <b>Data: </b> {lote && Formatter.getData(lote.data_alojamento)}
        <br />
        <b>Espécie: </b>
        {lote && lote.especie_explorada?.nome}
        <br />
        <b>Fase: </b>
        {lote && lote.fase_lote?.nome}
        <br />
        <b>Valor: </b>
        R$ {lote && Formatter.formatNumber(2, lote.valor)}
      </div>
    );
  };

  async function getPDF() {
    try {
      store.toggleLoader();
      const response = await apiMain.get(
        `/gerador/pdf/relatorio/${store.propriedade?.uuid}/lote/${state?.lote?.id}/historico/`,
        {
          responseType: 'blob',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `${store.token}`,
          },
        },
      );
      if (response.status !== 200) {
        throw new Error('Erro ao gerar o arquivo.');
      }
      const blob = response.data;
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      const filename = `Aquabit_${Formatter.dateToString(new Date(), '/')}_Historico_Lote_${state.lote?.codigo}.pdf`;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      X9Logger.report(error as Error);
      store.notificar('Ocorreu um erro ao gerar o arquivo. Tente novamente mais tarde!');
    } finally {
      store.toggleLoader();
    }
  }
  return state.sync ? (
    <Body gridColumns="350px 1fr">
      <div>
        <Display
          back={() =>
            history.push({
              pathname: '/producao/povoamento',
              state: { page: location.state ? location.state.page : null },
            })
          }
          title={state.lote?.codigo}
        >
          {/* <Info>
        <b>Espécie:</b> {state.lote?.especie_explorada.nome || 'Sem Espécie'}
        </Info>*/}
          <Info>
            <b>Fornecedor:</b> {state.lote?.fornecedor?.nome}
          </Info>
          <Info>
            <b>Tanques ({state.tanques?.length || 0}) : </b> {state.tanques?.map((tanque) => tanque?.nome).join(',')}
          </Info>
          <Info>
            <b>Fase:</b> {state.lote?.fase_lote?.nome}
          </Info>
          <Info>
            <b>Valor:</b> R$ {Formatter.formatNumber(2, state.lote?.valor)}
          </Info>
          <Info>
            <b>Peso Médio (g):</b> {state.lote ? Formatter.formatNumber(3, state.lote.peso_medio) : 0}
          </Info>
          <Info>
            <b>Qtd de animais:</b> {Formatter.formatNumber(0, state.lote?.qtd_animais || 0)}
          </Info>
          <Info>
            <b>Biomassa (Kg):</b> {Formatter.formatNumber(3, state.lote ? state.lote.biomassa : 0)}
          </Info>
        </Display>
        <div style={{ margin: '0 10px', display: 'flex' }}>
          <Botao
            disabled={!temPermissaoEscrita}
            onClick={() => {
              validaInteracao()
                ? modalExclusao.open()
                : store.notificar('O lote contém registros e não pode ser excluído!');
            }}
            variant="contained"
            cor="#FC7467"
          >
            Excluir
          </Botao>
          <Botao
            disabled={!temPermissaoEscrita}
            onClick={() => {
              // validaInteracao()
              //   ? modalEditar.open()
              //   : store.notificar('O lote contém registros e não pode ser editado!');

              modalEditar.open();
            }}
            variant="contained"
            cor="#00C853"
          >
            Editar
          </Botao>
        </div>
      </div>

      <Display title="Histórico do lote">
        {state.lote && state.lote.historicos.length > 0 ? (
          <>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                variant="outlined"
                size="small"
                endIcon={<img src={'/images/exportPDF.svg'} />}
                onClick={(event: any) => {
                  event.stopPropagation();
                  getPDF();
                }}
              >
                Baixar
              </Button>
            </div>
            <Historico historico={state.lote.historicos} />
          </>
        ) : (
          <p style={{ width: '100', textAlign: 'center', margin: '10px' }}>
            Não existe nenhum registro para esse lote!
          </p>
        )}
      </Display>

      <Confirmar
        title="Tem certeza que deseja excluir?"
        open={modalExclusao.state}
        content={<ExcluirLote />}
        handleClose={modalExclusao.close}
        onCancel={modalExclusao.close}
        onConfirm={deletar}
      />
      {modalEditar.state && (
        <EditarLote
          open={modalEditar.state}
          lote={state.lote!}
          handleClose={() => {
            sync();
            modalEditar.close();
          }}
        />
      )}
    </Body>
  ) : null;
};

export default observer(HistoricoLote);
