/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useState } from 'react';

import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from '@material-ui/lab';
import { Accordion, AccordionDetails, AccordionSummary, Paper, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Item } from './styles';
import { apiMain, apiV2 } from '../../services/utils';
import useStore from '../../services/hooks/useStore';
import Formatter from '../../services/Formatter';
import { Box, Button } from '@mui/material';
import X9Logger from '../../services/X9Logger';

type Props = {
  historico: Historico2;
};

const Historico: React.FC<Props> = ({ historico }) => {
  const store = useStore();

  const [finalizado, setFinalizado] = useState<Finalizado>({} as Finalizado);

  async function SearchApi(tanqueID: number) {
    const { data } = await apiV2.get<Historico2>(`/tanque/historico/`, {
      params: {
        tanque: historico.result.id,
        propriedade: store.propriedade!.id,
        lote: tanqueID,
      },
    });

    setFinalizado(data.result.lotes_ativos[0]);
  }

  async function getPDF(loteId: number) {
    try {
      store.toggleLoader();
      const response = await apiMain.get(
        `/gerador/pdf/relatorio/${store.propriedade?.uuid}/tanque/${historico.result.id}/historico/?lote=${loteId}`,

        {
          responseType: 'blob',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `${store.token}`,
          },
        },
      );
      // window.open(response.request.responseURL, '_blank');
      if (response.status !== 200) {
        throw new Error('Erro ao gerar o arquivo.');
      }
      const blob = response.data;
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      const filename = `Aquabit_${Formatter.dateToString(new Date(), '/')}_Historico_Tanque_${
        historico.result.tanque
      }.pdf`;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.log(error);
      X9Logger.report(error as Error);
      store.notificar('Ocorreu um erro ao gerar o arquivo. Tente novamente mais tarde!');
    } finally {
      store.toggleLoader();
    }
  }
  return (
    <main
      style={{
        overflowY: 'scroll',
        scrollBehavior: 'smooth',
      }}
    >
      {historico.result.lotes_ativos || historico.result.lotes ? (
        historico.result.lotes_ativos.map((item) => {
          return (
            <Item key={item.id_lote}>
              <Accordion
                className="accordion"
                style={{
                  borderBottom: '4px solid #D5D5D5',
                }}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />} arial-controls="panella-content" id="panela-header">
                  <Box display={'flex'} alignItems={'center'} gap={2}>
                    <Typography>
                      LOTE: {item.nome} - {item.especie}
                      <span
                        style={{
                          minWidth: '100px',
                          border: 'solid 1px #000',
                          padding: '.2rem',
                          color: '#8CBA43',
                          marginLeft: '1rem',
                        }}
                      >
                        {item.fase}
                      </span>
                    </Typography>
                    <Button
                      variant="outlined"
                      size="small"
                      endIcon={<img src={'/images/exportPDF.svg'} />}
                      onClick={(event) => {
                        event.stopPropagation();
                        getPDF(item.id_lote);
                      }}
                    >
                      Baixar
                    </Button>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <div style={{ width: '100%' }}>
                    <Timeline align="alternate">
                      {item.dados &&
                        item.dados.map((dado, index) => {
                          switch (dado.tipo) {
                            case 'povoamento':
                              return (
                                <TimelineItem key={index} style={{ width: '100%' }}>
                                  <TimelineOppositeContent>
                                    <Typography variant="body2" style={{ color: ' #808080', fontWeight: 'bold' }}>
                                      {dado.data.split(' ')[0]}
                                    </Typography>
                                  </TimelineOppositeContent>
                                  <TimelineSeparator>
                                    <TimelineDot style={{ backgroundColor: '#EC7722', padding: '15px' }}></TimelineDot>
                                    {index !== item.dados.length - 1 && <TimelineConnector />}
                                  </TimelineSeparator>
                                  <TimelineContent>
                                    <Paper
                                      elevation={3}
                                      style={{
                                        padding: '6px 10px',
                                      }}
                                    >
                                      <Typography variant="h6" component="h1" style={{ color: '#EC7722' }}>
                                        Povoamento
                                      </Typography>
                                      <Typography>
                                        {Formatter.formatNumber(2, dado.quantidade).replace(',00', '')} un -{' '}
                                        {Formatter.formatNumber(3, dado.peso)} g
                                      </Typography>
                                    </Paper>
                                  </TimelineContent>
                                </TimelineItem>
                              );
                            case 'biometria':
                              return (
                                <TimelineItem key={index}>
                                  <TimelineOppositeContent>
                                    <Typography variant="body2" style={{ color: ' #808080', fontWeight: 'bold' }}>
                                      {dado.data.split(' ')[0]}
                                    </Typography>
                                  </TimelineOppositeContent>
                                  <TimelineSeparator>
                                    <TimelineDot style={{ backgroundColor: '#049CE7', padding: '15px' }}></TimelineDot>
                                    {index !== item.dados.length - 1 && <TimelineConnector />}
                                  </TimelineSeparator>
                                  <TimelineContent>
                                    <Paper
                                      elevation={3}
                                      style={{
                                        padding: '6px 10px',
                                      }}
                                    >
                                      <Typography variant="h6" component="h1" style={{ color: '#049CE7' }}>
                                        Biometria
                                      </Typography>
                                      <Typography>
                                        Amostragem: {dado.qtd_peixes} - Peso Médio:
                                        {Formatter.formatNumber(3, dado.peso_medio)} g
                                      </Typography>
                                    </Paper>
                                  </TimelineContent>
                                </TimelineItem>
                              );
                            case 'transferencia_entrada':
                              return (
                                <TimelineItem key={index}>
                                  <TimelineOppositeContent>
                                    <Typography variant="body2" style={{ color: ' #808080', fontWeight: 'bold' }}>
                                      {dado.data.split(' ')[0]}
                                    </Typography>
                                  </TimelineOppositeContent>
                                  <TimelineSeparator>
                                    <TimelineDot style={{ backgroundColor: '#5F5D5B', padding: '15px' }}></TimelineDot>
                                    {index !== item.dados.length - 1 && <TimelineConnector />}
                                  </TimelineSeparator>
                                  <TimelineContent>
                                    <Paper
                                      elevation={3}
                                      style={{
                                        padding: '6px 10px',
                                      }}
                                    >
                                      <Typography
                                        variant="h6"
                                        component="h1"
                                        style={{ color: ' #808080', fontWeight: 'bold' }}
                                      >
                                        Transferência Entrada
                                      </Typography>
                                      <Typography>
                                        {`${dado.lote_origem} / ${dado.tanque_origem} : ${Formatter.formatNumber(
                                          2,
                                          dado.quantidade,
                                        ).replace(',00', '')}un`}
                                      </Typography>
                                    </Paper>
                                  </TimelineContent>
                                </TimelineItem>
                              );
                            case 'transferencia_saida':
                              return (
                                <TimelineItem key={index}>
                                  <TimelineOppositeContent>
                                    <Typography variant="body2" style={{ color: ' #808080', fontWeight: 'bold' }}>
                                      {dado.data.split(' ')[0]}
                                    </Typography>
                                  </TimelineOppositeContent>
                                  <TimelineSeparator>
                                    <TimelineDot style={{ backgroundColor: '#5F5D5B', padding: '15px' }}></TimelineDot>
                                    {index !== item.dados.length - 1 && <TimelineConnector />}
                                  </TimelineSeparator>
                                  <TimelineContent>
                                    <Paper
                                      elevation={3}
                                      style={{
                                        padding: '6px 10px',
                                      }}
                                    >
                                      <Typography variant="h6" component="h1" style={{ color: '#5F5D5B' }}>
                                        Transferência - Saída
                                      </Typography>
                                      <Typography>
                                        <Typography>
                                          {`${dado.lote_destino} / ${dado.tanque_destino} : ${Formatter.formatNumber(
                                            2,
                                            dado.quantidade,
                                          ).replace(',00', '')}un`}
                                        </Typography>
                                      </Typography>
                                    </Paper>
                                  </TimelineContent>
                                </TimelineItem>
                              );
                            case 'morte':
                              return (
                                <TimelineItem key={index}>
                                  <TimelineOppositeContent>
                                    <Typography variant="body2" style={{ color: ' #808080', fontWeight: 'bold' }}>
                                      {dado.data.split(' ')[0]}
                                    </Typography>
                                  </TimelineOppositeContent>
                                  <TimelineSeparator>
                                    <TimelineDot style={{ backgroundColor: '#F44336', padding: '15px' }}></TimelineDot>
                                    {index !== item.dados.length - 1 && <TimelineConnector />}
                                  </TimelineSeparator>
                                  <TimelineContent>
                                    <Paper
                                      elevation={3}
                                      style={{
                                        padding: '6px 10px',
                                      }}
                                    >
                                      <Typography variant="h6" component="h1" style={{ color: ' #F44336' }}>
                                        Mortalidade
                                      </Typography>
                                      <Typography>
                                        {Formatter.formatNumber(2, dado.quantidade).replace(',00', '')} un -{' '}
                                        {dado.causa}
                                      </Typography>
                                    </Paper>
                                  </TimelineContent>
                                </TimelineItem>
                              );
                            case 'vendas':
                              return (
                                <TimelineItem key={index}>
                                  <TimelineOppositeContent>
                                    <Typography variant="body2" style={{ color: ' #808080', fontWeight: 'bold' }}>
                                      {dado.data.split(' ')[0]}
                                    </Typography>
                                  </TimelineOppositeContent>
                                  <TimelineSeparator>
                                    <TimelineDot style={{ backgroundColor: '#00C853', padding: '15px' }}></TimelineDot>
                                    {index !== item.dados.length - 1 && <TimelineConnector />}
                                  </TimelineSeparator>
                                  <TimelineContent>
                                    <Paper
                                      elevation={3}
                                      style={{
                                        padding: '6px 10px',
                                      }}
                                    >
                                      <Typography variant="h6" component="h1" style={{ color: ' #00C853' }}>
                                        Vendas
                                      </Typography>
                                      <div
                                        style={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          justifyContent: 'space-between',
                                        }}
                                      >
                                        <Typography style={{ fontSize: '.9rem' }}>
                                          {Formatter.formatNumber(2, dado.quantidade).replace(',00', '')} un (PM:{' '}
                                          {Formatter.formatNumber(3, dado.peso_medio)}g)
                                        </Typography>
                                        <Typography
                                          style={{ color: ' #808080', fontWeight: 'bold', fontSize: '.9rem' }}
                                        >
                                          Biomassa: {Formatter.formatNumber(3, dado.biomassa / 1000)}Kg
                                        </Typography>
                                      </div>
                                    </Paper>
                                  </TimelineContent>
                                </TimelineItem>
                              );
                            case null || undefined:
                              return (
                                <TimelineItem key={index}>
                                  <TimelineOppositeContent>
                                    <Typography style={{ width: '100', textAlign: 'center', margin: '10px' }}>
                                      Não existe nenhum registro para esse lote!
                                    </Typography>
                                  </TimelineOppositeContent>
                                </TimelineItem>
                              );
                            default:
                              return null;
                          }
                        })}
                    </Timeline>
                  </div>
                </AccordionDetails>
              </Accordion>
            </Item>
          );
        })
      ) : (
        <>
          {historico.result.lotes_ativos && historico.result.lotes && (
            <p style={{ width: '100', textAlign: 'center', margin: '10px' }}>
              Não existe nenhum registro para esse lote!
            </p>
          )}
        </>
      )}

      {historico &&
        historico.result.lotes.map((item) => {
          return (
            <Item key={item.id}>
              <Accordion
                className="accordion"
                style={{
                  borderBottom: '4px solid #D5D5D5',
                }}
                onClick={() => SearchApi(item.id)}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />} arial-controls="panella-content" id="panela-header">
                  <Box display={'flex'} alignItems={'center'} gap={2}>
                    <Typography>
                      LOTE: {item.nome} - {item.codigo} {item.especie_explorada__nome}
                      <span
                        style={{
                          minWidth: '130px',
                          border: 'solid 1px #000',
                          padding: '.2rem',
                          marginLeft: '1rem',
                        }}
                      >
                        FINALIZADO
                      </span>
                    </Typography>
                    <Button
                      variant="outlined"
                      size="small"
                      endIcon={<img src={'/images/exportPDF.svg'} />}
                      onClick={(event) => {
                        event.stopPropagation();
                        getPDF(item.id);
                      }}
                    >
                      Baixar
                    </Button>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <div style={{ width: '100%' }}>
                    <Timeline align="alternate">
                      {finalizado && Object.keys(finalizado).length ? (
                        finalizado.dados.map((dado, index) => {
                          switch (dado.tipo) {
                            case 'povoamento':
                              return (
                                <TimelineItem key={index} style={{ width: '100%' }}>
                                  <TimelineOppositeContent>
                                    <Typography variant="body2" style={{ color: ' #808080', fontWeight: 'bold' }}>
                                      {dado.data.split(' ')[0]}
                                    </Typography>
                                  </TimelineOppositeContent>
                                  <TimelineSeparator>
                                    <TimelineDot style={{ backgroundColor: '#EC7722', padding: '15px' }}></TimelineDot>
                                    {index !== finalizado.dados.length - 1 && <TimelineConnector />}
                                  </TimelineSeparator>
                                  <TimelineContent>
                                    <Paper
                                      elevation={3}
                                      style={{
                                        padding: '6px 10px',
                                      }}
                                    >
                                      <Typography variant="h6" component="h1" style={{ color: '#EC7722' }}>
                                        Povoamento
                                      </Typography>
                                      <Typography>
                                        {Formatter.formatNumber(2, dado.quantidade).replace(',00', '')} un -{' '}
                                        {Formatter.formatNumber(3, dado.peso)} g
                                      </Typography>
                                    </Paper>
                                  </TimelineContent>
                                </TimelineItem>
                              );
                            case 'biometria':
                              return (
                                <TimelineItem key={index}>
                                  <TimelineOppositeContent>
                                    <Typography variant="body2" style={{ color: ' #808080', fontWeight: 'bold' }}>
                                      {dado.data.split(' ')[0]}
                                    </Typography>
                                  </TimelineOppositeContent>
                                  <TimelineSeparator>
                                    <TimelineDot style={{ backgroundColor: '#049CE7', padding: '15px' }}></TimelineDot>
                                    {index !== finalizado.dados.length - 1 && <TimelineConnector />}
                                  </TimelineSeparator>
                                  <TimelineContent>
                                    <Paper
                                      elevation={3}
                                      style={{
                                        padding: '6px 10px',
                                      }}
                                    >
                                      <Typography variant="h6" component="h1" style={{ color: '#049CE7' }}>
                                        Biometria
                                      </Typography>
                                      <Typography>
                                        Amostragem: {dado.qtd_peixes} - Peso Médio:
                                        {Formatter.formatNumber(3, dado.peso_medio)} g
                                      </Typography>
                                    </Paper>
                                  </TimelineContent>
                                </TimelineItem>
                              );
                            case 'transferencia_entrada':
                              return (
                                <TimelineItem key={index}>
                                  <TimelineOppositeContent>
                                    <Typography variant="body2" style={{ color: ' #808080', fontWeight: 'bold' }}>
                                      {dado.data.split(' ')[0]}
                                    </Typography>
                                  </TimelineOppositeContent>
                                  <TimelineSeparator>
                                    <TimelineDot style={{ backgroundColor: '#5F5D5B', padding: '15px' }}></TimelineDot>
                                    {index !== finalizado.dados.length - 1 && <TimelineConnector />}
                                  </TimelineSeparator>
                                  <TimelineContent>
                                    <Paper
                                      elevation={3}
                                      style={{
                                        padding: '6px 10px',
                                      }}
                                    >
                                      <Typography
                                        variant="h6"
                                        component="h1"
                                        style={{ color: ' #808080', fontWeight: 'bold' }}
                                      >
                                        Transferência Entrada
                                      </Typography>
                                      <Typography>
                                        {`${dado.lote_origem} / ${dado.tanque_origem} : ${Formatter.formatNumber(
                                          2,
                                          dado.quantidade,
                                        ).replace(',00', '')}un`}
                                      </Typography>
                                    </Paper>
                                  </TimelineContent>
                                </TimelineItem>
                              );
                            case 'transferencia_saida':
                              return (
                                <TimelineItem key={index}>
                                  <TimelineOppositeContent>
                                    <Typography variant="body2" style={{ color: ' #808080', fontWeight: 'bold' }}>
                                      {dado.data.split(' ')[0]}
                                    </Typography>
                                  </TimelineOppositeContent>
                                  <TimelineSeparator>
                                    <TimelineDot style={{ backgroundColor: '#5F5D5B', padding: '15px' }}></TimelineDot>
                                    {index !== finalizado.dados.length - 1 && <TimelineConnector />}
                                  </TimelineSeparator>
                                  <TimelineContent>
                                    <Paper
                                      elevation={3}
                                      style={{
                                        padding: '6px 10px',
                                      }}
                                    >
                                      <Typography variant="h6" component="h1" style={{ color: '#5F5D5B' }}>
                                        Transferência - Saída
                                      </Typography>
                                      <Typography>
                                        <Typography>
                                          {`${dado.lote_destino} / ${dado.tanque_destino} : ${Formatter.formatNumber(
                                            2,
                                            dado.quantidade,
                                          ).replace(',00', '')}un`}
                                        </Typography>
                                      </Typography>
                                    </Paper>
                                  </TimelineContent>
                                </TimelineItem>
                              );
                            case 'morte':
                              return (
                                <TimelineItem key={index}>
                                  <TimelineOppositeContent>
                                    <Typography variant="body2" style={{ color: ' #808080', fontWeight: 'bold' }}>
                                      {dado.data.split(' ')[0]}
                                    </Typography>
                                  </TimelineOppositeContent>
                                  <TimelineSeparator>
                                    <TimelineDot style={{ backgroundColor: '#F44336', padding: '15px' }}></TimelineDot>
                                    {index !== finalizado.dados.length - 1 && <TimelineConnector />}
                                  </TimelineSeparator>
                                  <TimelineContent>
                                    <Paper
                                      elevation={3}
                                      style={{
                                        padding: '6px 10px',
                                      }}
                                    >
                                      <Typography variant="h6" component="h1" style={{ color: ' #F44336' }}>
                                        Mortalidade
                                      </Typography>
                                      <Typography>
                                        {Formatter.formatNumber(2, dado.quantidade).replace(',00', '')} un -{' '}
                                        {dado.causa}
                                      </Typography>
                                    </Paper>
                                  </TimelineContent>
                                </TimelineItem>
                              );
                            case 'vendas':
                              return (
                                <TimelineItem key={index}>
                                  <TimelineOppositeContent>
                                    <Typography variant="body2" style={{ color: ' #808080', fontWeight: 'bold' }}>
                                      {dado.data.split(' ')[0]}
                                    </Typography>
                                  </TimelineOppositeContent>
                                  <TimelineSeparator>
                                    <TimelineDot style={{ backgroundColor: '#00C853', padding: '15px' }}></TimelineDot>
                                    {index !== finalizado.dados.length - 1 && <TimelineConnector />}
                                  </TimelineSeparator>
                                  <TimelineContent>
                                    <Paper
                                      elevation={3}
                                      style={{
                                        padding: '6px 10px',
                                      }}
                                    >
                                      <Typography variant="h6" component="h1" style={{ color: ' #00C853' }}>
                                        Vendas
                                      </Typography>
                                      <div
                                        style={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          justifyContent: 'space-between',
                                        }}
                                      >
                                        <Typography style={{ fontSize: '.9rem' }}>
                                          {Formatter.formatNumber(2, dado.quantidade).replace(',00', '')} un (PM:{' '}
                                          {Formatter.formatNumber(3, dado.peso_medio)} g)
                                        </Typography>
                                        <Typography
                                          style={{ color: ' #808080', fontWeight: 'bold', fontSize: '.9rem' }}
                                        >
                                          Biomassa: {Formatter.formatNumber(3, dado.biomassa / 1000)} Kg
                                        </Typography>
                                      </div>
                                    </Paper>
                                  </TimelineContent>
                                </TimelineItem>
                              );
                            case null || undefined:
                              return (
                                <TimelineItem key={index}>
                                  <TimelineOppositeContent>
                                    <Typography style={{ width: '100', textAlign: 'center', margin: '10px' }}>
                                      Não existe nenhum registro para esse lote!
                                    </Typography>
                                  </TimelineOppositeContent>
                                </TimelineItem>
                              );
                            default:
                              return null;
                          }
                        })
                      ) : (
                        <p style={{ width: '100', textAlign: 'center', margin: '10px' }}>Carregando lote!</p>
                      )}
                    </Timeline>
                  </div>
                </AccordionDetails>
              </Accordion>
            </Item>
          );
        })}
    </main>
  );
};

export default Historico;
